import React, { useState, useEffect } from 'react';
import { Instagram, Twitter } from 'react-feather';
import { useForm, ValidationError } from '@formspree/react';

function App() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [state, handleSubmit] = useForm('myzygzjo');

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const difference = +new Date('2025-01-09') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen">
      <div className="bg-black p-4 md:p-8 flex flex-col justify-between relative">
        <img
          src="/logo.png"
          alt="Logo"
          className="absolute top-4 left-4 h-16 md:h-32"
        />
        <div className="flex flex-col justify-center flex-grow items-center mt-20 md:mt-0">
          <h1 className="text-2xl md:text-4xl font-light text-white mb-6 md:mb-10 tracking-wider text-center leading-normal">
            Subscribe for{' '}
            <span className="font-extrabold capitalize">BITE</span>
            <br /> launch invite
          </h1>
          <p className="text-white mb-4 md:mb-6 font-light tracking-wider text-base md:text-lg text-center">
            Be notified when we launch our app
            <br /> for a limited number of users.
          </p>
          <div className="relative w-full max-w-[400px]">
            {state.succeeded ? (
              <div className="text-white text-center">
                <p className="text-xl md:text-2xl font-light mb-2 tracking-wider">
                  See you soon!
                </p>
              </div>
            ) : (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col space-y-4 justify-center items-center"
              >
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Type your email address"
                  className="w-full p-2 pl-4 rounded-md text-black bg-white outline-none focus:ring-0 focus:border-transparent placeholder-gray-300 placeholder-font-light"
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
                <button
                  type="submit"
                  disabled={state.submitting}
                  className="capitalize w-fit bg-white text-black px-4 py-2 rounded-md font-light text-xs md:text-sm hover:font-semibold transition-all duration-300 flex items-center justify-center shadow-md"
                >
                  {state.submitting ? (
                    <svg
                      className="animate-spin h-4 w-4 md:h-5 md:w-5 text-black"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    'SIGN ME UP'
                  )}
                </button>
              </form>
            )}
          </div>
        </div>
        <div className="flex justify-center items-center mt-8 md:mt-0">
          <div className="flex flex-col md:flex-row justify-between items-center w-full md:w-[300px]">
            <p className="text-white text-sm mb-2 md:mb-0">© 2024 Bite</p>
            <div className="flex space-x-4">
              <a
                href="https://www.instagram.com/getbiteapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram className="w-5 h-5 md:w-6 md:h-6 text-white hover:cursor-pointer hover:scale-110 transition-all duration-300" />
              </a>
              <a
                href="https://x.com/biteapp_sa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter className="w-5 h-5 md:w-6 md:h-6 text-white hover:cursor-pointer hover:scale-110 transition-all duration-300" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white flex justify-center items-center text-white p-4 md:p-0">
        <div className="w-full max-w-[400px] md:w-96 md:h-96 bg-black rounded-full flex flex-col justify-center items-center shadow-2xl py-8 md:py-0">
          <h2 className="text-lg md:text-xl mb-2">
            <span className="font-extrabold capitalize">BITE</span> - launching
            in
          </h2>
          <table className="text-center">
            <tbody>
              <tr className="text-2xl md:text-4xl font-bold">
                <td>{String(timeLeft.days).padStart(2, '0')}</td>
                <td className="px-1 md:px-2 font-light">:</td>
                <td>{String(timeLeft.hours).padStart(2, '0')}</td>
                <td className="px-1 md:px-2 font-light">:</td>
                <td>{String(timeLeft.minutes).padStart(2, '0')}</td>
                <td className="px-1 md:px-2 font-light">:</td>
                <td>{String(timeLeft.seconds).padStart(2, '0')}</td>
              </tr>
              <tr className="text-xs md:text-sm font-light text-gray-400">
                <td>days</td>
                <td></td>
                <td>hours</td>
                <td></td>
                <td>minutes</td>
                <td></td>
                <td>seconds</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default App;
